html {
  scroll-behavior: smooth;
}

summary::marker {
  content: "";
}

a {
  border: 0;
  color: hsl(193deg, 87%, 30%);
}
a:hover {
  color: #18bfef;
}

.centerButton {
  display: block;
  margin: auto;
  padding: 0;
}
.centerButton a {
  padding: 1rem;
}

/* #region  Nav */
.nav2 {
  background-color: #464b52;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  display: flex;
  justify-content: space-between;
}
.nav2 ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.nav2 ul a {
  color: white;
  padding: 1rem;
  text-transform: uppercase;
  transition: all 1s;
  letter-spacing: 2px;
}
.nav2 ul a:hover {
  background-color: hsl(215deg, 8%, 40%);
  color: #18bfef;
}
.nav2 .icons a {
  display: grid;
  place-items: center;
}
.nav2 .activeLink {
  color: #1e252d !important;
  background-color: #fff;
}
.nav2 .activeLink:hover {
  background-color: #fff;
}

/* #endregion */
/* #region  Blog Detail */
.post .goToTopFloat {
  top: 1rem;
  right: 3vw;
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: hsl(0deg, 0%, 25%);
}
.post .goToTopFloat svg {
  color: white;
}
.post .centerText {
  display: block;
  text-align: center;
}
.post img {
  width: 100%;
  max-width: 75%;
  display: block;
  margin: auto;
}
.post .fileName {
  position: relative;
  top: 5px;
  border-bottom-color: transparent;
  margin: 0;
  padding: 7px;
  display: inline;
  font-size: 1rem;
  font-weight: bold;
}
.post .arrowNavigation {
  display: flex;
  justify-content: space-between;
}
.post img[src*="#center"] {
  display: block;
  margin: auto;
}
.post img[src*="#keepDimensions"] {
  width: initial;
}

/* #endregion *//*# sourceMappingURL=index.css.map */