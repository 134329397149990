$aTagColor: hsl(193, 87%, 30%);
$linkColor: #18bfef;
$bgc: #464b52;

html {
  scroll-behavior: smooth;
}

summary {
  &::marker {
    content: "";
  }
}



a {
  border: 0;
  color: $aTagColor;

  &:hover {
    color: $linkColor;
  }
}

.centerButton{
  display: block;
  margin: auto;
  padding: 0;
  a{
    padding: 1rem;
  }
}

/* #region  Nav */

.nav2 {
  background-color: $bgc;
  font-family: "Source Sans Pro", Helvetica, sans-serif;

  display: flex;
  justify-content: space-between;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    a {
      color: white;
      padding: 1rem;
      text-transform: uppercase;
      transition: all 1s;
      letter-spacing: 2px;
      &:hover {
        background-color: hsl(215, 8%, 40%);
        color: $linkColor;
      }
    }
  }
  .icons {
    a {
      display: grid;
      place-items: center;
    }
    // padding: 0;
  }

  .activeLink {
    color: #1e252d !important;

    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
}

/* #endregion */


/* #region  Blog Detail */

.post{

  .goToTopFloat{
    top: 1rem;
    right: 3vw;
    // right: 1rem;
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: hsl(0, 0%, 25%);
    svg{

      color: white;
    }
  }

  .centerText{
    display: block;
    text-align: center;
  }

  img{
    width: 100%;
    max-width: 75%;
    display: block;
    margin: auto;
  }

  .fileName {
    position: relative;
    top: 5px;
    border-bottom-color: transparent;
    margin: 0;
    padding: 7px;
    display: inline;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .arrowNavigation {
    display: flex;
    justify-content: space-between;
}

img[src*="#center"] {
  display: block;
  margin: auto;
}
img[src*="#keepDimensions"] {
  width: initial;
}
}

/* #endregion */